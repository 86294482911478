import { Injectable } from '@angular/core';
import { Hub } from '@aws-amplify/core';
import { hub } from 'src/app/app.const';
import { AppLogService } from './app-log.service';

@Injectable({
  providedIn: 'root',
})
export class AppAuthService {
  private requests: Promise<any>[] = [];

  execute<T>(request: Promise<void | T>, requestName?: string): Promise<void | T> {
    AppLogService.info(`OnAuthRequestStarted ${requestName ? ':' + requestName : ''}`);
    this.requests.push(request);
    Hub.dispatch(hub.channel.http.name, {
      event: hub.channel.auth.event.start,
    });

    return new Promise((resolve, reject) => {
      request
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          AppLogService.error(`OnAuthRequestFailed ${requestName ? ':' + requestName : ''}`);
          AppLogService.error(error);

          if (error.code) {
            switch (error.code) {
              case hub.channel.auth.exception.UserNotFoundException:
              case hub.channel.auth.exception.NotAuthorizedException:
              case hub.channel.auth.exception.UserNotConfirmedException:
              case hub.channel.auth.exception.PasswordResetRequiredException:
                break;
              default:
                Hub.dispatch(hub.channel.http.name, {
                  event: hub.channel.http.event.error,
                  data: {
                    title: error.message,
                  },
                });
            }
          }

          reject(error);
        })
        .finally(() => {
          AppLogService.info(`OnAuthRequestEnded ${requestName ? ':' + requestName : ''}`);
          this.removeRequest(request);
          if (this.requests.length === 0) {
            Hub.dispatch(hub.channel.http.name, {
              event: hub.channel.auth.event.end,
            });
          }
        });
    });
  }

  private removeRequest(req: Promise<any>) {
    const index = this.requests.indexOf(req);
    if (index >= 0) {
      this.requests.splice(index, 1);
    }
  }
}
