// maintenance.service.ts

import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { AppApiService } from '@src/app/core/services/app-api.service';
import { api } from '@src/app/app.const';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  constructor(private appApiService: AppApiService) {}

  checkMaintenanceStatus(): Observable<boolean> {
    return from(this.appApiService.getWithouToken(api.webview.name, 'maintenance', null)).pipe(
      map((data: any) => data as boolean)
    );
  }
}
