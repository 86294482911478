import { ErrorHandler, Injectable } from '@angular/core';
import { AppLogService } from '../services/app-log.service';

@Injectable({
  providedIn: 'root',
})
export class AppErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    AppLogService.error(`An unknown error has occurred:' ${error}`);
  }
}
