import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { Observable } from 'rxjs';
import { route } from 'src/app/app.route';
import { AppAuthService } from '../services/app-auth.service';
import { AppLogService } from '../services/app-log.service';
import { AppRouteService } from '../services/app-route.service';

@Injectable()
export class AppUserTypeGuard {
  constructor(
    private _appAuthService: AppAuthService,
    private _appRouteService: AppRouteService
  ) { }

  canActivate(
    active: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Promise((resolve) => {
      this._appAuthService
        .execute(Auth.currentAuthenticatedUser())
        .then(async (user: any) => {
          if (active.data.userTypes) {
            const userType = user.signInUserSession.idToken.payload['custom:user-type'];
            if (userType && active.data.userTypes.indexOf(userType) > -1) {
              resolve(true);
            } else {
              this._appRouteService.navigate(`${route.home.path}`);
              resolve(false);
            }
          } else {
            resolve(true);
          }
        })
        .catch(error => {
          AppLogService.error(error);
          this._appRouteService.navigate(`${route.home.path}`, {
            queryParams: { returnUrl: state.url },
          });

          resolve(false);
        });
    });
  }
}
