import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Amplify } from '@aws-amplify/core';
import { environment } from 'src/environments/environment';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from '@src/app/app-routing.module';
import { AppComponent } from '@src/app/app.component';
import { SharedModule } from './shared/shared.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { AppErrorHandler } from './core/handlers/app-error.handler';
import { AppAuthGuard } from './core/guards/app-auth.guard';
import { AppApiService } from './core/services/app-api.service';
import { AppAuthService } from './core/services/app-auth.service';
import { AppLogService } from './core/services/app-log.service';
import { AppRouteService } from './core/services/app-route.service';
import { BreadcrumbService } from './shared/components/breadcrumb/breadcrumb.service';
import { MepcoComponent } from './mepco-component';

import { AppUserTypeGuard } from './core/guards/app-user-type.guard';
import { AxiosConfigFactory, AxiosInterceptorService } from './core/interceptors/axios.interceptor';
import { HttpErrorInterceptor } from './core/interceptors/http.interceptor';
import { NgIdleModule } from '@ng-idle/core';
import { DATE_PIPE_DEFAULT_TIMEZONE } from '@angular/common';
import { QuillModule } from 'ngx-quill';

export const APP_PROVIDERS: Array<any> = [
  { provide: AppApiService, useClass: AppApiService },
  { provide: AppLogService, useClass: AppLogService },
  { provide: AppAuthService, useClass: AppAuthService },
  { provide: AppRouteService, useClass: AppRouteService },
];

Amplify.configure(environment.amplify);

@NgModule({
  declarations: [AppComponent, MepcoComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatButtonModule,
    SharedModule,
    NgIdleModule.forRoot(),
    QuillModule.forRoot(),
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    SharedModule,
  ],
  providers: [
    BreadcrumbService,
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    {
      provide: DATE_PIPE_DEFAULT_TIMEZONE,
      useValue: 'America/Chicago',
    },
    APP_PROVIDERS,
    AppAuthGuard,
    AppUserTypeGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: AxiosConfigFactory,
      deps: [AxiosInterceptorService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
