import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
  private updateSubject = new Subject<string>();
  private popSubject = new Subject<NavigationExtras>();

  onUpdate(): Observable<string> {
    return this.updateSubject.asObservable();
  }

  onPop(): Observable<NavigationExtras> {
    return this.popSubject.asObservable();
  }

  updateBreadcrumb(str: string) {
    this.updateSubject.next(str);
  }

  popBreadcrumb(extras?: NavigationExtras) {
    this.popSubject.next(extras);
  }
}
