import { Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { BehaviorSubject } from 'rxjs';
import { hub } from 'src/app/app.const';
import { AppLogService } from './app-log.service';

@Injectable({
  providedIn: 'root',
})
export class Request {
  apiName: string;
  path: string;
  init: any;
  callback: any;
}
export class AppApiService {
  private requests: Promise<any>[] = [];
  private data: any[] = [];
  public CoverageX: string = 'CoverageX';
  private sellerNameBhvSubj: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public sellerName$ = this.sellerNameBhvSubj.asObservable();
  /**
   * Make a GET request
   * @param {string} apiName - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  get(apiName: string, path: string, init: any, dispatchEvent: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAccessToken(init)
        .then(params => {
          resolve(this.execute(API.get(apiName, path, params), dispatchEvent));
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getWithouToken(apiName: string, path: string, init: any, dispatchEvent: boolean = true): Promise<any> {
    return new Promise(resolve => {
      resolve(this.execute(API.get(apiName, path, init), dispatchEvent));
    });
  }

  postWithouToken(apiName: string, path: string, init: any, dispatchEvent: boolean = true): Promise<any> {
    return new Promise(resolve => {
      resolve(this.execute(API.post(apiName, path, init), dispatchEvent));
    });
  }

  putWithoutToken(apiName: string, path: string, init: any, dispatchEvent: boolean = true): Promise<any> {
    return new Promise(resolve => {
      resolve(this.execute(API.put(apiName, path, init), dispatchEvent));
    });
  }

  /**
   * Make a GET all request. This method is useful for when you want to wait for more than one request to complete.
   * @param {Request[]} requests - A list of Request that need to be executed together.
   */
  getAll(requests: Request[]) {
    return new Promise(reject => {
      const promises: Promise<any>[] = [];

      Auth.currentSession()
        .then(session => {
          const token = session.getIdToken()?.getJwtToken();

          requests.forEach(async (r: Request) => {
            if (!r.init) {
              r.init = {};
            }

            r.init.headers = {
              Authorization: `Bearer ${token}`,
            };

            promises.push(API.get(r.apiName, r.path, r.init));
          });

          this.executeAll(promises)
            .then((results: any[]) => {
              if (results && results.length > 0) {
                requests.forEach((request, index) => {
                  request.callback(results[index]);
                });
              }
            })

            .catch(error => {
              reject(error);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /**
   * Make a POST request
   * @param {string} apiName - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  post(apiName: string, path: string, init: any, dispatchEvent: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAccessToken(init)
        .then(params => {
          this.data = params;
          this.trimData(this.data);
          resolve(this.execute(API.post(apiName, path, this.data), dispatchEvent));
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  /**
   * Make a PUT request
   * @param {string} apiName - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  put(apiName: string, path: string, init: any, dispatchEvent: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAccessToken(init)
        .then(params => {
          this.data = params;
          this.trimData(this.data);
          resolve(this.execute(API.put(apiName, path, this.data), dispatchEvent));
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  trimData(obj) {
    if (obj) {
      Object.keys(obj).forEach(key => {
        if (typeof obj[key] !== 'object' && typeof obj[key] === 'string') {
          obj[key] = obj[key].trim();
        } else if (typeof obj[key] === 'object') {
          this.trimData(obj[key]);
        }
      });
    }
  }

  /**
   * Make a PATCH request
   * @param {string} apiName - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  patch(apiName: string, path: string, init: any, dispatchEvent: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAccessToken(init)
        .then(params => {
          resolve(this.execute(API.patch(apiName, path, params), dispatchEvent));
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  /**
   * Make a DEL request
   * @param {string} apiName - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  del(apiName: string, path: string, init: any, dispatchEvent: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAccessToken(init)
        .then(params => {
          resolve(this.execute(API.del(apiName, path, params), dispatchEvent));
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  private getAccessToken(init: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(session => {
          const token = session.getIdToken()?.getJwtToken();

          if (!init) {
            init = {};
          }

          init.headers = {
            Authorization: `Bearer ${token}`,
          };

          resolve(init);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  private execute<T>(request: Promise<any>, dispatchEvent: boolean = true, requestName?: string): Promise<any> {
    AppLogService.info(`OnRequestStarted ${requestName ? ':' + requestName : ''}`);
    if (dispatchEvent) {
      this.requests.push(request);
      Hub.dispatch(hub.channel.http.name, {
        event: hub.channel.http.event.start,
      });
    }

    return new Promise((resolve, reject) => {
      request
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          if (dispatchEvent) {
            AppLogService.error(`OnRequestFailed ${requestName ? ':' + requestName : ''}`);
            AppLogService.error(error);

            Hub.dispatch(hub.channel.http.name, {
              event: hub.channel.http.event.error,
              message: error.message,
              data: error.response ? error.response.data : null,
            });
          }

          reject(error);
        })
        .finally(() => {
          AppLogService.info(`OnRequestEnded ${requestName ? ':' + requestName : ''}`);
          if (dispatchEvent) {
            this.removeRequest(request);
            if (this.requests.length === 0) {
              Hub.dispatch(hub.channel.http.name, {
                event: hub.channel.http.event.end,
              });
            }
          }
        });
    });
  }

  private executeAll<T>(requests: Promise<any>[]): Promise<any> {
    AppLogService.info(`OnRequestAllStarted`);
    Hub.dispatch(hub.channel.http.name, {
      event: hub.channel.http.event.start,
    });

    return new Promise((resolve, reject) => {
      Promise.all(requests)
        .then(result => {
          AppLogService.info(`OnRequestAllSucceed`);
          resolve(result);
        })
        .catch(error => {
          AppLogService.error(`OnRequestAllFailed`);
          AppLogService.error(error);

          Hub.dispatch(hub.channel.http.name, {
            event: hub.channel.http.event.error,
            message: error.message,
            data: error.response ? error.response.data : null,
          });
          reject(error);
        })
        .finally(() => {
          AppLogService.info(`OnRequestAllEnded`);

          Hub.dispatch(hub.channel.http.name, {
            event: hub.channel.http.event.end,
          });
        });
    });
  }

  private removeRequest(req: Promise<any>) {
    const index = this.requests.indexOf(req);
    if (index >= 0) {
      this.requests.splice(index, 1);
    }
  }

  public setSellerName(sellerName: string = ''): void {
    this.sellerNameBhvSubj.next(sellerName);
  }

  public setLocalStorage(key: string, val: any) {
    localStorage.setItem(key, val);
  }

  public getLocalStorage(key): any {
    return localStorage.getItem(key);
  }

  public removeLocalStorage(key): void {
    localStorage.removeItem(key);
  }
}

export interface ObjecKey {
  [name: string]: string;
}
