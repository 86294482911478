import { Component } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { route } from './app.route';
import { AppAuthService } from './core/services/app-auth.service';
import { AppRouteService } from './core/services/app-route.service';
import { AccountsFilterService } from './services/accounts/accounts-filter.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private idle: Idle,
    private appAuthService: AppAuthService,
    private appRouteService: AppRouteService,
    protected accountFilterService: AccountsFilterService
  ) {


    this.startIdle();
  }


  startIdle() {
    this.idle.setIdle(10);

    this.idle.setTimeout(Number(process.env.NG_APP_IDLE_TIMEOUT) || 600);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => { });
    this.idle.onTimeoutWarning.subscribe(() => { });


    // Start watch when time is up.
    this.idle.onIdleEnd.subscribe(() => { });
    this.idle.onTimeout.subscribe(() => {

      this.onLogout();
    });

    this.idle.watch(true);
  }

  onLogout(): void {
    this.appAuthService.execute(Auth.signOut()).then(() => {
      this.appRouteService.navigate(route.home.path).then(() => {
        this.idle.watch(false);

      });
    });
  }

}
