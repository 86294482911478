import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AppRouteService {
  constructor(private _router: Router) {}

  navigate(route: string, extras?: NavigationExtras): Promise<boolean> {
    return this._router.navigate([route], extras);
  }

  navigateByUrl(url: string, extras?: NavigationExtras): Promise<boolean> {
    return this._router.navigateByUrl(url, extras);
  }
}
