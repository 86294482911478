import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Observable } from 'rxjs';
import { route } from 'src/app/app.route';
import { AppAuthService } from '../services/app-auth.service';
import { AppLogService } from '../services/app-log.service';
import { AppRouteService } from '../services/app-route.service';

@Injectable()
export class AppAuthGuard {
  constructor(
    private _appAuthService: AppAuthService,
    private _appRouteService: AppRouteService
  ) {}

  canActivate(
    active: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Promise((resolve, reject) => {
      this._appAuthService
        .execute(Auth.currentAuthenticatedUser())
        .then((user: CognitoUser) => {
          if (active.data.roles) {
            if (
              user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'] &&
              user
                .getSignInUserSession()
                ?.getAccessToken()
                .payload['cognito:groups'].some((group: any) => active.data.roles.indexOf(group) > -1)
            ) {
              resolve(true);
            } else {
              this._appRouteService.navigate(`${route.home.path}`);
              resolve(false);
            }
          } else {
            resolve(true);
          }
        })
        .catch(error => {
          AppLogService.error(error);
          this._appRouteService.navigate(`${route.home.path}`, {
            queryParams: { returnUrl: state.url },
          });

          resolve(false);
        });
    });
  }
}
