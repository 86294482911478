import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({ providedIn: 'root' })
export class AxiosInterceptorService {
  intercept() {
    axios.interceptors.response.use(
      response => response,
      error => {
        const status = error.response ? error.response.status : null;
        if (status === 429) {
          error.response.data.message = 'Please Wait A Few Minutes Before You Try Again';
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );
  }
}

export function AxiosConfigFactory(axiosIntercept: AxiosInterceptorService): any {
  return () => axiosIntercept.intercept();
}
