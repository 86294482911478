import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbComponent } from '@src/app/shared/components/breadcrumb/breadcrumb.component';
import { DataTablesModule } from 'angular-datatables';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { IfRolesDirective } from '../core/directives/ifRoles.directive';
import { IfUserTypesDirective } from '../core/directives/ifUserTypes.directive';
import { AppApiService } from '../core/services/app-api.service';
import { MaterialModule } from '../material.module';
import { ChangePasswordDialogComponent } from '../mepco-public/header-tabs/home/components/logIn/dialog/change-password/change-password-dialog.component';
import { ForceChangePasswordDialogComponent } from '../mepco-public/header-tabs/home/components/logIn/dialog/force-change-password/force-change-password-dialog.component';
import { ResetPasswordDialogComponent } from '../mepco-public/header-tabs/home/components/logIn/dialog/reset-password/reset-password-dialog.component';
import { ResetTempPasswordDialogComponent } from '../mepco-public/header-tabs/home/components/logIn/dialog/reset-temp-password/reset-temp-password-dialog.component';
import { UserAnswerComponent } from '../mepco-public/header-tabs/home/components/logIn/dialog/user-answer/user-answer.component';
import { UserQuestionsAnswersComponent } from '../mepco-public/header-tabs/home/components/logIn/dialog/user-questions-answers/user-questions-answers.component';
import { PaymentService } from '../mepco-webview/account/views/make-payment/services/payment.service';
import { ConfirmDialogComponent } from './components/dialog/confirm-dialog.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DateFilterComponent } from './components/filter/date-filter.component';
import { HubComponent } from './components/hub/hub.component';
import { FilterComponent } from './components/table/filter.component';
import { TableComponent } from './components/table/table.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { FooterComponent } from './footer/footer.component';
import { MepcoDatePipe } from './pipes/mepco-date-pipe';
import { SharedRoutingModule } from './shared-routing.module';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    ChangePasswordDialogComponent,
    ForceChangePasswordDialogComponent,
    ResetPasswordDialogComponent,
    ResetTempPasswordDialogComponent,
    FooterComponent,
    HubComponent,
    TableComponent,
    FilterComponent,
    DialogComponent,
    UserQuestionsAnswersComponent,
    UserAnswerComponent,
    IfRolesDirective,
    IfUserTypesDirective,
    DateFilterComponent,
    TextEditorComponent,
    MepcoDatePipe,
    ConfirmDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    MaterialModule,
    MatPasswordStrengthModule.forRoot(),
    QuillModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [
    FooterComponent,
    BreadcrumbComponent,
    ChangePasswordDialogComponent,
    ForceChangePasswordDialogComponent,
    ResetPasswordDialogComponent,
    ResetTempPasswordDialogComponent,
    FormsModule,
    ReactiveFormsModule,
    TableComponent,
    MaterialModule,
    HubComponent,
    FilterComponent,
    DialogComponent,
    UserQuestionsAnswersComponent,
    UserAnswerComponent,
    IfRolesDirective,
    IfUserTypesDirective,
    DateFilterComponent,
    TextEditorComponent,
    MepcoDatePipe,
    ConfirmDialogComponent,
  ],
  providers: [AppApiService, PaymentService, provideNgxMask()],
})
export class SharedModule { }
