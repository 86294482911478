import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppAuthService } from '../services/app-auth.service';
import { MaintenanceService } from '../services/maintenance.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceGuard {
  constructor(
    private maintenanceService: MaintenanceService,
    private router: Router,
    private appAuthService: AppAuthService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url = state.url;
    return this.checkMaintenanceStatus(url);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url = state.url;
    return this.checkMaintenanceStatus(url);
  }

  checkMaintenanceStatus(url: string): Observable<boolean> {
    return this.maintenanceService.checkMaintenanceStatus().pipe(
      map((result: boolean) => {
        if (url.includes('maintenance') && !result) {
          this.router.navigate(['/home']);
          return true;
        }

        if (!url.includes('maintenance') && result) {
          this.router.navigate(['/maintenance']);
          this.logOut();
          return false;
        }

        return true;
      }),
      catchError(() => {
        this.router.navigate(['/maintenance']);
        this.logOut();
        return of(false);
      })
    );
  }

  logOut() {
    this.appAuthService.execute(Auth.signOut());
  }
}
